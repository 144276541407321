<template>
    <div>
        <slot/>
    </div>
</template>

<style>
/*html {
    @apply bg-gray-50
}*/

.link {
    @apply text-indigo-500 hover:text-indigo-700 cursor-pointer;
}

.link-gray {
    @apply text-gray-800 hover:text-indigo-700 cursor-pointer;
}

.for-mobile {
    @apply px-3 sm:px-6
}

h1 {
    @apply text-2xl sm:text-3xl font-bold tracking-tight text-gray-900
}

h2 {
    @apply text-xl font-bold tracking-tight text-gray-900
}

h3 {
    @apply text-lg tracking-tight text-gray-900 font-semibold
}

h4 {
    @apply tracking-tight text-gray-900
}

p {
    @apply text-gray-700
}

.input {
    @apply appearance-none block w-full px-3 py-2 border border-gray-200 rounded-md shadow-sm placeholder-gray-400 sm:text-sm
}

.button-primary {
    @apply py-3 rounded-md text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700
}
</style>
